<template>
    <div class="select-language" :class="{'select-language--open': selectingLanguage}">
        <img
            :src="`/img/flags/${locales.selected}.png`"
            alt=""
            @click.stop="selectingLanguage = !selectingLanguage"
            width="36"
            height="20"
        >

        <ul v-if="selectingLanguage">
            <li
                v-for="item in filteredLanguages"
                :key="item"
            >
                <img
                    :src="`/img/flags/${item}.png`"
                    alt=""
                    @click="changeLocale(item, selectingLanguage = false)"
                    width="36"
                    height="20"
                >
            </li>
        </ul>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
    name: 'SelectLanguage',
    data() {
        return {
            selectingLanguage: false,
        }
    },
    computed: {
        ...mapState(['locales']),
        filteredLanguages() {
            return this.locales.lang.filter(item => item !== this.locales.selected)
        },
    },
    methods: {
        ...mapActions(['changeLocale']),
    },
    mounted() {
        document.getElementById('app').addEventListener('click', () => {
            this.selectingLanguage = false
        })
    },
}
</script>

<style lang="scss">
.select-language {
    position: absolute;
    top: 64px;
    right: 24px;
    width: 60px;

    &--open {
        background-color: rgba(62, 176, 161, 1);
    }

    ul {
        margin: 0;
        padding: 0;
    }

    li {
        list-style-type: none;
    }

    img {
        position: relative;
        right: 0;
        transform: translateX(50%);
        border-radius: 3px;
    }
}
</style>
