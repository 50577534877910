import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home.vue'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/guides',
        name: 'Guides',
        component: () => import(/* webpackChunkName: "guides" */ '@/views/Guides.vue'),
    },
    {
        path: '/guide/:category/:id',
        name: 'Guide',
        component: () => import(/* webpackChunkName: "guide" */ '@/views/Guide.vue'),
        props: true,
    },
    {
        path: '/cryptos',
        name: 'Cryptos',
        component: () => import(/* webpackChunkName: "cryptos" */ '@/views/Cryptos.vue'),
    },
    {
        path: '/crypto/:id',
        name: 'Crypto',
        component: () => import(/* webpackChunkName: "crypto" */ '@/views/Crypto.vue'),
        props: true,
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'not-found',
        component: () => import(/* webpackChunkName: "not-found" */ '@/views/NotFound.vue'),
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
