import { createI18n } from 'vue-i18n'

import store from './store'

function loadLocaleJSON() {
    let locales = require.context(
        './assets/locales',
        true,
        /[A-Za-z0-9-_,\s]+\.json$/i
    )
    const messages = {}

    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i)

        // Only get files from the current country
        if (matched && matched.length > 1 && matched.input.includes(`/${store.state.locales.selected}/`)) {
            const locale = matched[1]
            messages[locale] = locales(key)
        }
    })

    return messages
}

// 2. Create i18n instance with options
const i18n = createI18n({
    locale: store.state.locale,
    fallbackLocale: 'en',
    messages: loadLocaleJSON(),
})

export default i18n
