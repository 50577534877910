import axios from 'axios'
import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate"

const store = createStore({
    state: {
        apiUrl: process.env.VUE_APP_FIREBASE_URL,
        device: null,
        locales: {
            selected: 'en',
            lang: [
                'dk',
                'bg',
                'en',
            ],
        },
        geoLocation: null,
    },
    mutations: {
        setDevice(state, payload) {
            state.device = payload
        },
        setLocale(state, payload) {
            state.locales.selected = payload
        },
        setGeoLocation(state, payload) {
            state.geoLocation = payload
        },
    },
    actions: {
        setDevice({ commit }) {
            const { innerWidth: width } = window

            switch (true) {
                case (width >= 1500):
                    return commit('setDevice', 'desktop-higher')
                case (width >= 1300):
                    return commit('setDevice', 'desktop-middle')
                case (width >= 1024):
                    return commit('setDevice', 'desktop-lower')
                case (width >= 768):
                    return commit('setDevice', 'tablet')
                case (width >= 414):
                    return commit('setDevice', 'mobile-higher')
                case (width >= 375):
                    return commit('setDevice', 'mobile-middle')
                default:
                    return commit('setDevice', 'mobile')
            }
        },
        getGeoLocation({ state, commit }) {
            axios
                .get(`${state.apiUrl}/getIpAndCountry`)
                .then(response => {
                    commit('setGeoLocation', response.data)

                    switch(response.data.country_code2.toLowerCase()) {
                        case 'dk':
                            commit('setLocale', response.data.country_code2.toLowerCase())

                            location.reload()
                            break
                        case 'bg':
                            commit('setLocale', response.data.country_code2.toLowerCase())

                            location.reload()
                            break
                    }
                })
                .catch(error => console.log('Error - geoL:', error))
        },
        changeLocale({ commit }, payload) {
            commit('setLocale', payload)
            setTimeout(() => {
                window.scrollTo(0, 0)
            }, 0)

            location.reload()
        },
        lazyLoadImages() {
            let lazyImages = [...document.querySelectorAll('img[data-src]')]

            lazyImages.forEach((image) => {
                if (image.src) return

                if (image.getBoundingClientRect().top < window.innerHeight + 200) {
                    if (image.src === image.getAttribute('data-src')) {
                        return
                    }

                    image.src = image.getAttribute('data-src')
                }
            })
        },
    },
    modules: {
    },
    plugins: [
        createPersistedState()
    ]
})

export default store
