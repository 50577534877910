<template>
    <h1 v-html="homeContent.heading"></h1>

    <div class="intro-text">
        <div v-html="homeContent.intro.text"></div>

        <ul>
            <li
                v-for="item in homeContent.intro.list"
                :key="item"
                v-html="item"
            >
            </li>
        </ul>
    </div>

    <div class="home">
        <div class="container">
            <div class="content-wrapper exchanges">
                <h2 v-html="homeContent.exchanges.heading"></h2>

                <div v-html="homeContent.exchanges.text"></div>

                <ul>
                    <li
                        v-for="item in homeContent.exchanges.list"
                        :key="item.name"
                        class="exchange-item"
                    >
                        <svg-icon :icon="`logo/${item.logo}`" />

                        <div class="exchange-item__top">
                            <div>
                                <h3 v-html="item.bonus.title"></h3>

                                <p>{{ item.bonus.text }}</p>
                            </div>

                            <div>
                                <h3 v-html="item.kyc.title"></h3>

                                <p>{{ item.kyc.text }}</p>
                            </div>
                        </div>

                        <div class="exchange-item__fees">
                            <h3 v-html="item.fees.title"></h3>

                            <ul>
                                <li
                                    v-for="fee in item.fees.list"
                                    :key="fee"
                                >
                                    <p v-html="fee"></p>
                                </li>
                            </ul>
                        </div>

                        <div class="exchange-item__pros-cons">
                            <div class="exchange-item__pros-cons--pros">
                                <h3 v-html="item.pros.title"></h3>

                                <ul>
                                    <li
                                        v-for="pro in item.pros.list"
                                        :key="pro"
                                    >
                                        {{ pro }}
                                    </li>
                                </ul>
                            </div>

                            <div class="exchange-item__pros-cons--cons">
                                <h3 v-html="item.cons.title"></h3>

                                <ul>
                                    <li
                                        v-for="con in item.cons.list"
                                        :key="con"
                                    >
                                        {{ con }}
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="exchange-item__description">
                            <h3 v-html="item.description.title"></h3>

                            <p v-html="item.description.text"></p>
                        </div>

                        <a
                            :href="item.affiliateLink"
                            rel="noopener"
                            target="_blank"
                            class="button"
                        >
                            {{ item.buttonText }}
                        </a>
                    </li>
                </ul>
            </div>

            <div class="content-wrapper">
                <h2 v-html="homeContent.ledger.heading"></h2>

                <p v-html="homeContent.ledger.text"></p>

                <p>
                    {{ homeContent.ledger.guides.firstText }}
                    <router-link to="/guide/wallets/ledger">
                        {{ homeContent.ledger.guides.firstLink }}
                    </router-link> {{ homeContent.ledger.guides.secondText }}
                    <router-link to="/guide/apps/ledger-live">
                        {{ homeContent.ledger.guides.secondLink }}
                    </router-link> {{ homeContent.ledger.guides.thirdText }}
                </p>

                <p>
                    {{ homeContent.ledger.buyLedger.text }}
                    <a
                        href="https://shop.ledger.com/products/ledger-nano-s?r=39aa20cece4c"
                        rel="noopener"
                        target="_blank"
                        class="button"
                    >
                        {{ homeContent.ledger.buyLedger.buttonText }}
                    </a>
                </p>
            </div>
        </div>
    </div>
</template>

<script>

import SvgIcon from '@/components/SvgIcon.vue'

export default {
    name: 'Home',
    components: {
        SvgIcon,
    },
    computed: {
        homeContent() {
            return this.$i18n.messages.home
        },
        currentLocale() {
            return this.$i18n.locale
        },
    },
    methods: {

    },
    mounted() {
    },
}

</script>

<style lang="scss">
.intro-text {
    margin-bottom: 24px;

    p {
        font-weight: 700;
    }

    li {
        margin-bottom: 10px;
    }
}

.exchanges {
    h2 {
        @media (min-width: 768px) {
            font-size: 18px;
        }

        @media (min-width: 1024px) {
            font-size: 20px;
        }
    }

    ul {
        padding: 0;
    }

    li {
        margin-bottom: 24px;
        list-style-type: none;

        &:last-child {
            margin-bottom: 0;
        }
    }

    p {
        margin-bottom: 6px;
    }
}

.exchange-item {
    margin: 0 -10px;
    padding: 12px 16px;
    border-radius: 0 0 0 36px;
    background-color: #1f5072;
    box-shadow: #00000029 0 2px 5px;

    li {
        margin-bottom: 6px;
    }

    .svg-container {
        font-size: 24px;

        @media (min-width: 375px) {
            font-size: 36px;
        }

        @media (min-width: 768px) {
            font-size: 40px;
        }
    }

    &__top {
        display: flex;
        color: #00d4d4;

        div {
            width: 50%;

            p {
                color: #aee3e3;
            }

            &:first-child {
                margin-right: 12px;
            }
        }
    }

    &__fees {
        h3 {
            color: #b2800c;
        }

        li {
            margin-left: 0;
            color: #c9bda0;
            list-style-type: none;
        }
    }

    &__pros-cons {
        display: flex;

        &--pros {
            width: 50%;

            h3 {
                color: #009719;
            }

            li {
                margin-right: 10px;
                margin-left: 10px;
                color: #e0f5e3;
                list-style-type: disc;
            }
        }

        &--cons {
            width: 50%;

            h3 {
                color: #a73b3b;
            }

            li {
                margin-left: 10px;
                color: #ddc9c9;
                list-style-type: disc;
            }
        }
    }

    &__description {
        h3 {
            font-size: 16px;

            @media (min-width: 375px) {
                font-size: 18px;
            }
        }
    }
}
</style>
