<template>
    <router-link to="/">
        <img
            src="/img/logo/cryptonight.png"
            alt=""
            class="cryptonight-logo"
        >
    </router-link>

    <div id="nav">
        <router-link to="/">Home</router-link>

        <router-link to="/guides">Guides</router-link>

        <router-link to="/cryptos">Cryptos</router-link>

        <select-language />
    </div>

    <router-view/>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import SelectLanguage from '@/components/SelectLanguage'

export default {
    name: 'App',
    components: {
        SelectLanguage,
    },
    computed: {
        ...mapState(['device', 'locales', 'geoLocation']),
    },
    methods: {
        ...mapActions([
            'setDevice',
            'getGeoLocation',
        ]),
        throttle(func, wait) {
            let time = Date.now()

            return () => {
                if ((time + wait - Date.now()) < 0) {
                    func()

                    time = Date.now()
                }
            }
        },
    },
    watch: {
        $route() {
            setTimeout(() => {
                window.scrollTo(0, 0)
            }, 0)

            // TODO: Remove when enough guides
            if (this.$route.name === 'Guides') {
                if (this.device.includes('tablet') || this.device.includes('desktop-higher')) {
                    return document.body.style.height = '100vh'
                }
            }

            // Width has to be higher because of the text
            if (this.$route.name === 'Crypto' && this.device.includes('desktop')) {
                document.getElementById('app').style.width = '950px'
            } else document.getElementById('app').style.width = ''

            // TODO: Remove this when more than 4 cryptos
            if (this.$route.name === 'Cryptos' && this.device !== 'mobile') {
                document.body.style.height = '100vh'
            } else document.body.style.height = ''
        },
    },
    mounted() {
        setTimeout(() => {
            window.scrollTo(0, 0)
        }, 1000)

        // Only run this one time
        if (!this.geoLocation) {
            this.getGeoLocation()
        }

        this.setDevice()
    },
}

/* eslint-disable */
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300;500;700;900&display=swap');

body {
    min-height: calc(100% + env(safe-area-inset-top));
    margin: 0;
    padding: 2.5em constant(safe-area-inset-right) 2.5em constant(safe-area-inset-left);
    overflow: auto;
    background: linear-gradient(170deg, rgba(0, 162, 141, 1) 25%, rgba(25, 22, 84, .9) 80%);
    background-repeat: repeat;
}

h1 {
    margin: 24px 0;
    font-size: 24px;
    text-align: center;

    @media (min-width: 1024px) {
        font-size: 32px;
    }
}

h2 {
    margin: 0 0 12px;
    font-size: 14px;
}

h3 {
    margin-bottom: 6px;
    font-size: 13px;
}

a, p, li {
    margin-top: 0;
    font-size: 14px;

    @media (min-width: 768px) {
        font-size: 16px;
    }
}

li {
    margin-bottom: 6px;
}

a {
    outline: none;
    color: #11e2e2;
    font-weight: 700;
    text-decoration: none;
    cursor: pointer;

    @media (min-width: 1024px) {
        &:hover {
            text-decoration: underline;
        }
    }
}

button {
    &:hover {
        cursor: pointer;
    }
}

label {
    user-select: none;
}

ul, li {
    margin: 0;
    padding: 0;
}

ul {
    padding-left: 16px;
}

li {
    list-style-type: disc;
}

input {
    // Remove blue color from autofill
    -webkit-box-shadow: 0 0 0 1000px #ffffff inset !important;
    box-shadow: 0 0 0 1000px #ffffff inset !important;

    &:hover {
        cursor: pointer;
    }
}

select {
    font-size: 16px;
}

p {
    &::first-letter {
        text-transform: capitalize;
    }
}

#app {
    position: relative;
    // min-height: 100%;
    padding: 16px;
    overflow-x: hidden;
    color: white;
    font-family: 'Roboto+Mono', sans-serif;
    text-align: left;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @media (min-width: 768px) {
        width: 600px;
        min-height: auto;
        margin: auto;
    }

    @media (min-width: 1000px) {
        width: 800px;
        margin: auto;
    }
}

#nav {
    display: flex;
    padding: 0;

    a {
        margin: 12px 12px 0 0;
        margin-right: 12px;
        color: #2c3e50;
        font-size: 18px;
        font-weight: 700;

        &.router-link-exact-active {
            color: #0d1115;
            text-decoration: underline;
        }
    }
}

.button {
    display: flex;
    width: fit-content;
    margin: 12px auto;
    padding: 6px 14px;
    border-radius: 6px;
    color: white;
    background-color: #ef6f14;
    font-size: 13px;
    font-weight: 700;
    text-decoration: none;
    text-shadow: #00000087 0 1px 2px;

    @media (min-width: 375px) {
        font-size: 14px;
    }

    @media (min-width: 1024px) {
        margin-top: 24px;
        font-size: 18px;
    }
}

.cryptonight-logo {
    width: 180px;
}

.container {
    display: flex;
    max-width: 300px;
    margin: 0 auto;
    flex-direction: column;

    @media (min-width: 375px) {
        max-width: 350px;
    }

    @media (min-width: 414px) {
        max-width: 400px;
    }

    @media (min-width: 768px) {
        max-width: 730px;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    @media (min-width: 1024px) {
        max-width: 900px;
        padding: 12px;
    }

    @media (min-width: 1300px) {
        max-width: 1190px;
        margin: 0 auto;
    }
}

.content-wrapper {
    position: relative;
    margin-bottom: 24px;
    padding: 16px 10px;
    border-radius: 6px;
    background-color: #174768;
    box-shadow: #00000052 0 3px 6px;

    h2 {
        margin-top: 0;
    }

    @media (min-width: 375px) {
        padding: 24px 12px;
    }

    @media (min-width: 414px) {
        padding: 24px;
    }

    @media (min-width: 768px) {
        height: fit-content;
        padding: 18px;
    }

    @media (min-width: 1300px) {
        margin-bottom: 36px;
        padding: 24px 32px;
    }
}
</style>
